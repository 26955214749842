import { LoginFlowBase, identificationTypes } from "./login-flow-base";

import * as events from "../events";

class VodacomZATier2 extends LoginFlowBase {
    handleIntegratorIframeIdentity() {
        const base = this.integratorIdentifyUrl.split('?')[0];
        this.integrator.customHeIframeIdentify(
            base,
            'he',
            {
                return: window.location.href,
                metadata: this.metadata
            }
        );
    }

    handleIntegratorIframeIdentityCustomPlan(plan) {
        const base = this.integratorIdentifyUrl.split('?')[0];

        this.integrator.customHeIframeIdentify(
            base,
            'he',
            {
                return: window.location.href,
                metadata: this.metadata,
                plan: plan
            }
        );
    }

    onMessage(event, onSuccess, onError) {
        //check the iframe event and then call the correct one
        const res = event.data;
        if (res.status_code == 200) {
            if (this.metadata['page_attributes-retry_plans']) {
                console.log('Found retry plans')
                // debugger
                let retryPlans = []
                try {
                    // array of 'page_attributes-retry_plans' is sent as comma seperated string, need to parse this to an array
                    retryPlans = this.metadata['page_attributes-retry_plans'].split(",")
                } catch (e) {
                    onError(res)
                }
                for (let p of retryPlans) {
                    if (!this.checkedPlans.includes(p)) {
                        console.log("checking plan " + p)
                        this.checkedPlans.push(p)
                        this.integrator.deleteIdentityFrame()
                        this.handleIntegratorIframeIdentityCustomPlan(p)
                        return
                    }
                }
            }
            onSuccess(res)
        } else if (res.status_code == 302) {
            if (res.jwt) {
                this.onSubscriptionExists(res)
            } else {
                onError(res)
            }
        } else {
            onError(res)
        }
    }

    identifyOnSuccess(response) { // overridable
        this.identity = response.identity;
        this.identified = response.identified;
        this.identifyCallFinished = true;
        if (this.identified) {
            this.redirectToLandingPage()
        } else {
            this.logger.debug(`[Identity Failure] id response`, response);
            this.tracker.track(events.IDENTIFY_FAILURE, {
                reference: response.reference,
                status_code: response.status_code,
            });
            this.identifyBackup()
        }
    }

    redirectToLandingPage() {
        // remove unload message
        window.onbeforeunload = null;

        const landingPageUrl = this.metadata['page_attributes-subscribe_landing_page'];

        if (landingPageUrl) {

            const landingPageURLOBJ = new URL(landingPageUrl)

            const url = this.getProductRedirectUrl(landingPageURLOBJ.origin, landingPageURLOBJ.pathname, {
                event_tracking_id: this.metadata.event_tracking_id
            });

            this.tracker.track(events.REDIRECT_TO_LANDING_PAGE, {
                landingPage: url
            });

            this.logger.info(`Flow (${this.name}): redirecting to landing page`, {
                "LPUrl": url
            });

            window.location.assign(url);
        } else {
            this.logger.info(`Flow (${this.name}): redirecting to base landing page URL`, {"LPUrl": window.location.origin});

            this.uiControl.showErrorMessage(`No subscription found`)
        }
    }

}

const vodacomZATier2FlowModule = new VodacomZATier2('Vodacom ZA Tier 2', identificationTypes.IFRAME, {
    contentClick: true,
    subscribeEndpoint: 'lp/tier2/subscribe',
    inactiveTimeout: true,
})
window.FlowModule = vodacomZATier2FlowModule;
