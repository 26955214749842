import * as events from '../events';

export default {
    bindWifiClickEvent(flow) {
        flow.dispatcher.addEventListener(events.WIFI_CLICK, () => {
            flow.logger.debug(`Flow (${flow.name}): wifi button clicked`);

            flow.tracker.track(events.WIFI_CLICK);

            window.location.reload();
        });
    },

    bindLanguageSwitcherEvent(flow) {
        flow.dispatcher.addEventListener(events.LANGUAGE_SWITCHED, (event) => {
            flow.logger.debug(`Flow (${flow.name}): language switcher clicked`);

            flow.tracker.track(events.LANGUAGE_SWITCHED, {
                data: {
                    locale: event.locale,
                },
            });

            window.location.href = event.href;
        });
    },

    /**
     * Track when a user has exited the flow (left the page);
     *
     * This event is triggered by the LandingPage object on window beforeunload
     *
     * @param {Object} flow A flow object
     */
    bindFlowExitEvent(flow) {
        flow.dispatcher.addEventListener(events.FLOW_EXIT, () => {
            if (window.unloadTriggered !== true) {
                window.unloadTriggered = true;
                flow.logger.debug(`Flow (${flow.name}): flow exit`);

                flow.tracker.track(events.FLOW_EXIT, {
                    data: {
                        flow_name: flow.name,
                        flow_step: flow.step,
                    },
                });
            }
        });
    },
};
